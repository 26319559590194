.rhsc-assistant {
  .bot-message-wrapper {
    display: flex;
    margin-bottom: 20px;
    font-size: 13px !important;

    .bot-message-action-icons-wrapper {
      margin-top: 5px;
    }

    .bot-message-action-icon {
      color: gray;
      margin-right: 7px;
      cursor: pointer;
      outline: none;
      border: none;
    }

    .bot-message-action-icon-disabled {
      cursor: default;
      color: gray;
      margin-right: 7px;
    }

    .source-link-wrapper {
      margin-top: 10px;
    }

    .bot-message-feedback-form {
      margin-top: 10px;

      textarea {
        font-size: 13px !important;
        width: 300px;
        outline: none;
      }

      .pf-v5-c-button {
        margin-top: 10px;
        font-size: 13px;
      }
    }
  }

  .bot-icon-wrapper {
    background-color: #fce3e3;
    color: #ee0000;
    height: 28px;
    min-width: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fbc5c5;
    margin-right: 10px;
  }

  .bot-icon {
    height: 15px;
  }
}
