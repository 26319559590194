.rhsc-assistant-loader {
  .pf-v5-c-title {
    font-family: var(
      --lwc-fontFamily,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Helvetica,
      Arial,
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol"
    );
  }
}
