.pf-c-select {
  --pf-c-select__toggle--before--BorderBottomColor: rgb(66, 66, 66);
}

.pf-v5-c-table {
  --pf-global--BorderColor--100: rgb(224, 224, 224);
}

.pf-c-chip-custom {
  background-color: rgb(255, 250, 245);
  border: rgb(255, 216, 108) 0.1rem solid;
}

.clone-button {
  &:hover {
    cursor: pointer;
    color: rgb(160, 160, 160);
  }
}

.pf-v5-c-toolbar__item {
  padding-right: 10px;
}

.table-load-more-button {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background-color: white;
}

.pf-v5-c-table__td {
  a {
    font-weight: 500 !important;
  }
}

.pf-v5-c-table__th {
  font-weight: 500 !important;
}
