.pf-v5-c-modal-box__title {
  font-size: 18px !important;
}

.pf-v5-c-modal-box__close > button {
  padding: 0;
}

.rhsc-assistant-vof-feedback {
  .pf-v5-c-form__label-text {
    font-size: 13px !important;
  }

  .pf-v5-c-form__group-control {
    font-size: 13px !important;
  }

  .pf-v5-c-form-control > select {
    font-size: 13px !important;
  }

  .pf-v5-c-form-control > textarea {
    font-size: 13px !important;
  }

  .pf-v5-c-helper-text__item-text {
    font-size: 13px !important;
  }

  .feedback-submission-text {
    font-size: 13px !important;
  }
}

.feedback-modal-action-button {
  font-size: 13px !important;
}
