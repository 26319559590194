.back-to-list-button {
  border: none;
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 0 8px;
  margin-right: 15px;
}

.back-to-list-button:hover {
  background-color: #004080;
  color: white;
}

.timeline-item-box {
  margin-bottom: 20px !important;
}
