.rhsc-assistant {
  .parent-container {
    display: flex;
    justify-content: flex-end;
  }

  .user-message-wrapper {
    font-size: 13px !important;
    background-color: #eaeaea;
    border-radius: 1.5rem;
    padding: 8px 16px;
    margin-bottom: 20px;
    width: 90%;
    margin-left: auto;
  }
}
