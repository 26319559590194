.chatbot {
  margin-bottom: var(--pf-v5-global--spacer--md);
  margin-right: 40px;
}

.bubble {
  border: 1px solid var(--pf-v5-global--BackgroundColor--dark-400);
  border-radius: 14px;
  padding: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--md);
  max-width: 100%;
  word-wrap: break-word;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: var(--pf-v5-global--palette--black-600);
  }
  28% {
    transform: translateY(-7px);
    background-color: var(--pf-v5-global--palette--black-400);
  }
  44% {
    transform: translateY(0px);
    background-color: var(--pf-v5-global--palette--black-200);
  }
}

.typing {
  height: 17px;
  .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    border-radius: 50%;
    display: inline-block;
    height: 7px;
    margin-right: 4px;
    margin-top: 6px;
    vertical-align: middle;
    width: 7px;

    &:nth-child(1) {
      animation-delay: 200ms;
    }
    &:nth-child(2) {
      animation-delay: 300ms;
    }
    &:nth-child(3) {
      animation-delay: 400ms;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
