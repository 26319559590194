.select-dropdown {
  .pf-c-select__menu {
    max-height: 200px;
    overflow: auto;
  }
}

.pf-v5-c-date-picker__helper-text {
  font-size: 14px;
}
