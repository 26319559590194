.sub-heading {
  display: inline-block;
  border-bottom: 2px solid #06c;
  font-weight: bold;
  margin-top: 10px;
}

.task-item {
  border-radius: 20px;
  padding: 12px;
  background: white;
  margin: 10px 0;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.task-item-row {
  margin-bottom: 7px;
}

.task-item-key {
  font-weight: bold;
}

.reviewer-email-dropdown {
  .pf-c-select__menu {
    max-height: 200px;
    overflow: auto;
  }
}

@media screen and (min-width: 300px) {
  .pf-c-form.pf-m-horizontal .pf-c-form__group {
    display: grid !important;
    grid-column-gap: 0.5rem !important;
    grid-template-columns:
      6.5rem
      var(--pf-c-form--m-horizontal__group-control--md--GridColumnWidth);
  }
}
