body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Red Hat Text";
}

@font-face {
  font-family: "Red Hat Display";
  src: url("./fonts/RedHatDisplay-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
}

@font-face {
  font-family: "Red Hat Text";
  src: url("./fonts/RedHatText-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
}
#root {
  height: 100%;
  height: -webkit-fill-available;
}

.main-content-routes {
  padding-bottom: 6rem;
}

.process-details--timeline {
  border-left: var(--pf-v5-global--BorderWidth--md) dashed
    var(--pf-v5-global--BorderColor--300);
  margin-left: var(--pf-v5-global--spacer--sm);
  margin-top: var(--pf-v5-global--spacer--sm);
}
.process-details--timeline-item {
  background-color: transparent;
  margin-left: calc(-1 * var(--pf-v5-global--spacer--sm));
}
.process-details--timeline-item:first-child {
  margin-top: calc(-1 * var(--pf-v5-global--spacer--sm));
}
.process-details--timeline-status {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  margin-left: calc(-1 * var(--pf-v5-global--spacer--sm));
}
