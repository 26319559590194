.rhsc-assistant {
  .header {
    padding: 16px;
    border: none;
    background-color: white;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      align-items: center;
    }

    .header-title {
      margin-right: auto;
      color: #7d7d7d;
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;
    }

    .header-action-icon {
      cursor: pointer;
      font-size: 18px;
      color: #151515;
      margin-right: 5px;
      outline: none;
      border: none;
    }
  }
}
