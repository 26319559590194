.pf-c-page__header {
  position: sticky;
  top: 0px;
  z-index: var(--pf-c-page__header--ZIndex);

  .pf-c-page__header-nav {
    padding: 0;
  }
}

.pf-v5-c-divider.pf-m-vertical.hr-vertical {
  --pf-c-divider--BackgroundColor: #555;
  padding: 15px 0 15px 0;
}

.header {
  .pf-v5-c-toolbar__content-section {
    justify-content: space-between;
  }
}

.pf-v5-c-nav__item {
  font-size: 14px;
}

.pf-v5-c-page__sidebar-body {
  background: rgb(21, 21, 21);
}
