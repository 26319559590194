.processes-list {
  margin-top: 50px;
  height: 62vh;
  text-align: center;
}

.process-icon {
  margin-bottom: 15px;
  height: 80px;
  width: 80px;
}

.pf-v5-c-card__title-text {
  font-weight: bold;
}
