.rhsc-assistant {
  font-family: var(
    --lwc-fontFamily,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol"
  );
  font-size: 13px !important;

  .pf-v5-c-card__header {
    display: none;
  }

  .pf-v5-c-input-group {
    height: 100px !important;

    textarea {
      font-size: 13px;
    }
  }

  .pf-v5-c-card {
    box-shadow: none;
  }

  .pf-v5-c-card__body {
    padding: 16px;
  }

  .chat-wrapper {
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .empty-conversation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .empty-conversation-bot-icon-wrapper {
      margin-top: 20px;
      background-color: #fce3e3;
      color: #ee0000;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #fbc5c5;
    }

    .empty-conversation-bot-icon {
      height: 30px;
    }

    .empty-conversation-text {
      color: #7d7d7d;
      font-size: 13px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .disclaimer-text {
    font-size: 13px;
    text-align: center;

    .text-toggler {
      background: none;
      border: none;
      color: blue;
    }
  }

  .powered-by-text {
    margin-top: 4vh;
    color: #ee0000;
  }

  .rhsc-assistant-conversations {
    background: white;
    height: calc(100vh - 91px);
    overflow-y: scroll;
    margin: 16px;
    padding: 10px;
    border-radius: 8px;

    .no-conversations-found {
      color: #7d7d7d;
      font-size: 13px;
      margin-top: 20vh;
      text-align: center;
    }

    .conversations-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .go-back-button {
      color: #7d7d7d;
      font-size: 13px;
      font-weight: 600;
    }

    .conversations-header-text {
      margin-right: auto;
      color: #7d7d7d;
      font-size: 13px;
      font-weight: 600;
    }

    .conversation-time {
      margin-bottom: 5px;
      margin-top: 25px;
      color: #7d7d7d;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .conversation-item {
    cursor: pointer;
    font-size: 13px !important;
    border-radius: 8px;
    padding: 5px 10px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .conversation-item:hover {
    background-color: #eaeaea;
  }

  @media screen and (max-width: 576px) {
    .card-0-2-1 {
      height: calc(100vh - 59px);
    }
  }
}
